export default {
  gamePlay: {
    playTitle: "Translate and respond!",
    playInstructions: "<p>Where was the last {{count}} blob of each color?<br /><br /> Check the color order at the top of your screen.<br />Only click the colors shown.</p>",
    playButton: 'Start translating!',
    enterNumbers: 'Enter the number of broken diodes on each circuit IN ORDER, starting with the first board.'
  },
  trials: {
    correct: "Correct sequence! Good job, technician!",
    incorrect: "Error! Error! Incorrect sequence, tech!"
  },
  rounds: {
    end: "End of Round {{round}}",
    playNext: "Play next round!",
    all: "Excellent! You got {{correct}} trials correct!",
    some: "Great job! You got {{correct}} trials correct in this round.",
    none: "Good try. Let's keep practicing!"
  },
  gameComplete: {
    playTitle: "Game Completed!",
    playInstructions: "Great work, keep practicing!",
    playButton: 'Return to games'
  },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
    instructions: [
        {
            'title': 'Master Electricians Wanted',
            'body': 'The Gigaflop circuits are flopping!<br>\n' +
                'Your on-screen sensor tells you which COLOR fuses are fizzing.',
            'buttonText': 'Okay!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Finding the Fizzing Fuses',
            'body': 'COUNT the fizzing fuses on each screen.<br>\n' +
                'Click to mark each one for repair!',
            'buttonText': 'Okay!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Enter the Repair Code',
            'body': 'How many fizzing fuses were on each circuit board?<br>'+
            'Enter them IN the ORDER you saw them, starting with the first board.',
            'buttonText': 'Okay!',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'yes',
            'buttonText2': 'no',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
}
