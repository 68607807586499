import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/ShortCircuit/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
    correct: 'correct.mp3',
    incorrect: 'incorrect.mp3',
    disabled: 'disabled.mp3',
    quizCorrect: 'quiz-correct.mp3',
    gameEnd: 'gameend.mp3',
    roundEnd: 'round-end.mp3',
    feedbackIncorrect: 'feedback-wrong.mp3',
    feedbackCorrect: 'feedback-correct.mp3',
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    backgroundPlay: 'background-play.webp',
    buttonOff: 'btn-off.webp',
    buttonRed: 'btn-red.webp',
    buttonGreen: 'btn-green.webp',
    buttonGrey: 'btn-grey.webp',
    buttonYellow: 'btn-yellow.webp',
    buttonBlue: 'btn-blue.webp',
    buttonOrange: 'btn-orange.webp',
    buttonPurple: 'btn-purple.webp',
    buttonOffset: 'btn-hidden.webp',
    modal: 'modal-input.webp',
    modalInput: 'modal-input.webp',
    correctCheck: 'check.svg',
    buttonIncorrect: 'btn-incorrect.webp',
  }
};

export default generateAssets(assetsDir, gameAssets);