//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from '../../../../../shared/GlobalMixins.js';
import GamePlayMixin from '../../../../../shared/GamePlayMixin.js';

import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';
import InitializeGameMixin from '../../../../../shared/InitializeGameMixin';

export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer'),
    AnswerModal: () => import(/* webpackChunkName: "modal" */ './AnswerModal')
  },
  data() {
    return {
      activeButtons: [],
      buttonIndexes: [],
      buttonReaction: null,
      buttonReactionClass: null,
      buttons: [],
      container: null,
      correctPresses: [],
      correctThisRound: 0,
      endRoundText: '',
      grid: {
        cols: 6,
        rows: 4
      },
      hintMode: true,
      hintedButton: [],
      incorrectAnswers: false,
      nextTrial: false,
      playDisabled: false,
      playStart: null,
      randomNumbers: {
        totalDots: null,
        totalTargetDots: null
      },
      sequenceLength: 0,
      sequencePosition: 1,
      showModal: false,
      showColorButtons: false,
      stimuliColor: null,
      totalAnswers: 0,
      totalAnswersPerTrial: 1,
      totalColors: 1,
      totalDots: 0,
      totalTargetDots: 0,
      trialCompleted: false,
      wrongOrder: false,
      isNewTrial: true
    };
  },
  methods: {
    /**
     * Initialize the game play by setting up the grid, and then starting the game loop.
     */
    async initializeGamePlay() {
      this.setGameExited(false);
      this.setTimer('false');
      this.setScore(0);
      this.container = this.$refs.gameplay;
      this.playDisabled = true;
      this.incorrectAnswers = false;
      this.setCurrentTrial(1);
      this.setCurrentRound(1);
      // reveal selected buttons for this session
      console.log('initializing gameplay');
      // loop for each round
      // get the user level, setup grid for first trial/round
      await this.getSettings(); // this will actually get the user, grid data
      await this.setupButtons();
      await this.animation.to(this.container, 2.5, {y: 0, ease: this.tweenExpo.easeOut});
      await this.sleep(100); // wait

      await this.showGameplayHints();
      await this.sleep(100); // wait
      this.playStart = new Date().getTime(); // set the play start time
    },
    /**
     * Get the user's level and game settings from the database.
     * @returns {Promise<void>}
     */
    async getSettings() {
      let gameData = await this.startGamePlay({
          gameId: this.gameId, playId: this.play_id
        });
        this.setGameLevelData(gameData);
        this.setRoundId(gameData.round_id);
        this.setLevel(this.gameLevelData.start_level);
        this.setRounds(5);
    },
    /**
     * Iterate through each row and column of the grid, creating a button object with
     * initial properties for each cell. Then push the button object into the 'buttons' array.
     */
    initializeButtonGrid() {
      Array.from({length: this.grid.rows}, (_, y) => {
        Array.from({length: this.grid.cols}, (_, x) => {
          this.buttons.push({
            x,
            y,
            correct: false,
            clicked: false,
            isActive: false,
            activeColor: false,
            reaction: null,
            hidden: false
          });
        });
      });
    },
    /**
     * Iterate through the total target dots, updating each button with target color,
     * active status, and other properties. Then, add the updated button to the activeButtons array.
     * Finally, push the total target dots count as a string into the sequence array.
     * @param targetColor
     * @param sequence
     */
    activateTargetDots(targetColor, sequence) {
      Array.from({length: this.totalTargetDots}, () => {
        const index = this.buttonIndexes.pop();
        const button = this.buttons[index];

        Object.assign(button, {
          color: targetColor,
          correct: true,
          activeColor: targetColor.name,
          isActive: true,
          reaction: null,
          index,
          hidden: false
        });

        this.activeButtons.push(button);
      });

      sequence.push(String(this.totalTargetDots));
    },
    /**
     * Iterate through the other colors array, and for each color, create the specified
     * number of dots by updating the button properties accordingly. Then, add the
     * updated button to the activeButtons array.
     * @param otherColors
     * @param otherDotAmounts
     */
    activateOtherColorDots(otherColors, otherDotAmounts) {
      otherColors.forEach((c, i) => {
        Array.from({length: otherDotAmounts[i]}, () => {
          const index = this.buttonIndexes.pop();
          const button = this.buttons[index];

          Object.assign(button, {
            color: otherColors[i],
            correct: false,
            reaction: null,
            activeColor: otherColors[i].name,
            index,
            isActive: true,
            hidden: false
          });

          this.activeButtons.push(button);
        });
      });
    },
    /**
     * Distribute the total number of dots among the target color and other colors.
     * @param gameSettings
     * @param otherColors
     * @returns {*[]}
     */
    distributeDotsAmongColors(gameSettings, otherColors) {

      this.totalDots = this.randomNumbers.totalDots
          .setMinMax(
              gameSettings.min_total_leds, gameSettings.max_total_leds
          ).getRandomIntegerInRange();

      // make sure we get a number that's less or equal to the max target dots
      let adjustedMaxTargetDots = Math.min(gameSettings.max_target_leds, this.totalDots);
      this.totalTargetDots = this.randomNumbers.totalTargetDots
          .setMinMax(
              gameSettings.min_target_leds, adjustedMaxTargetDots
          ).getRandomIntegerInRange();

      // get the total number of dots of the remaining colors
      // for each other color, we need to take a random subset of the remaining dots
      // the total number of each subset is the amount of dots of that color

      if (otherColors.length > 0) {
        // Make sure this.totalDots is greater than totalTargetDots but no more than gameSettings.max_total_leds
        this.totalDots = Math.min(
            Math.max(this.totalTargetDots + 1, this.totalDots),
            gameSettings.max_total_leds
        );

        // Make sure this.totalDots is at least equal to totalTargetDots + otherColors.length if it's less than or equal to max_total_leds
        if (this.totalDots <= gameSettings.max_total_leds) {
          this.totalDots = Math.max(this.totalDots, this.totalTargetDots + otherColors.length);
        }
      }

      let totalRemainingDots = this.totalDots - this.totalTargetDots;
      let otherDotAmounts = [];

      // Iterate through the otherColors array, and for each color (except the last one),
      // generate a random number of dots within a specific range and update the totalRemainingDots.
      // For the last color, assign the totalRemainingDots as the number of dots.
      otherColors.forEach((t, idx) => {
        if (idx === otherColors.length - 1) {
          otherDotAmounts.push(totalRemainingDots);
        } else {
          const otherColorDotsAmount = this.getRandomIntegerInRange(
              1,
              totalRemainingDots - (otherColors.length - (idx + 1))
          );
          otherDotAmounts.push(otherColorDotsAmount);
          totalRemainingDots -= otherColorDotsAmount;
        }
      });
      return otherDotAmounts;
    },
    /**
     * Setup the button grid for the current round.
     * @returns {Promise<unknown>}
     */
    async setupButtons() {
      this.showColorButtons = false;
      this.correctPresses = [];
      this.activeButtons = [];
      this.buttonIndexes = [];
      this.buttons = [];

      let gameSettings = this.gameLevelData.game_settings; // shorten this so it's easier to manage
      // get total colors from level data
      this.totalColors = Number(gameSettings.total_colors);
      this.sequenceLength = Number(gameSettings.stim_per_trial);

      // make sure the target color is passed to next stimuli if we're in the same trial
      let targetColor;
      let colorRange = this.shuffleArray(this.colorValues);

      if (this.isNewTrial) {
        targetColor = colorRange[0];
        this.stimuliColor = targetColor;
      } else {
        targetColor = this.stimuliColor;
        // make sure we're not preserving the targetColor, so it doesn't show in the distraction colors
        colorRange = colorRange = colorRange.filter(item => item !== targetColor);
      }

      let otherColors = [];
      let sequence = [];

      Array.from({length: this.totalColors - 1}, (_, i) => i).forEach(function (i) {
        if (i < colorRange.length) {
          otherColors.push(colorRange[i + 1]);
        }
      });

      let otherDotAmounts = this.distributeDotsAmongColors(gameSettings, otherColors);

      this.initializeButtonGrid();
      this.buttonIndexes = this.shuffleArray(Array.from({length: this.buttons.length}, (_, i) => i));

      this.activateTargetDots(targetColor, sequence);
      this.activateOtherColorDots(otherColors, otherDotAmounts);


      // get colors based on amount needed, min/max per color
      let selectedColors = [];

      this.totalAnswersPerTrial = this.totalTargetDots;
      selectedColors.push(targetColor);

      // set hints only if this is a new trial, otherwise we don't want to change the colors
      if (this.isNewTrial) {
        let hints = this.shuffleArray(selectedColors.map(a => a.name));
        this.setHints(hints);
      }

      this.isNewTrial = false;

      if (this.activeButtons.length > 0) {
        return true;
      }

      throw new Error('No active buttons were set for this round.');
    },
    /**
     * Start the game play mode, which for this game is really just resetting several things and starting the timer.
     * @returns {Promise<void>}
     */
    async startGamePlayMode() {
      this.hintMode = false;
      this.playDisabled = false;
      this.modalMessageReset();
    },
    /**
     * Show the button sequence for the current round.
     * @returns {Promise<unknown>}
     */
    async showButtonSequence() {
      // Add indexes to this.hintedButton
      this.hintedButton.push(...this.activeButtons.map(button => button.index));

      // Wait for Vue to update the DOM
      await this.$nextTick();
    },
    /**
     * Show the gameplay hints for the current round.
     * @param count
     * @returns {Promise<void>}
     */
    async showGameplayHints(count = 1) {
      this.setMusic(this.assets.audio.gamePlay, true);
      // show hints dependent on Level
      this.hintMode = true;
      this.setTimerStopAfter(1000);
      this.startTimer(200);

      await this.showButtonSequence();

      this.showColorButtons = true;

      await this.sleep(1000);

      if (this.gameExited) return; // make sure if the user exits at this stage, we don't keep going
      await this.startGamePlayMode();
    },
    /**
     * Get the proper image for the button based on its state.
     * @param the_button
     * @returns {string|*}
     */
    getButtonState(the_button) {
      if (the_button.hidden) return this.assets.images.buttonOffset;
      // answer was incorrect, show them the incorrect answer button
      // if (!the_button.isActive && the_button.clicked || !the_button.activeColor) {
      //   return this.assets.images.buttonOff;
      //   // this needs to actually be an "incorrect"
      // }

      if (this.showColorButtons) {
        if (this.hintedButton.includes(the_button.index)) {
          return this.assets.images['button' + this.capitalize(the_button.activeColor)];
        }
      }

      return this.assets.images.buttonOff;
      // this should disable all buttons if the play is set to disabled
      // if (this.playDisabled) buttonClass += ' play-disabled'
      // if it's active (i.e. it's being displayed for some reason), set the right class
    },
    /**
     * Set the reaction for a button.
     * @param button
     * @param reaction
     * @param sleep
     * @returns {Promise<void>}
     */
    async setButtonReaction(button, reaction, sleep = 3000) {
      if (this.buttons[button.index]) this.buttons[button.index].reaction = reaction;
    },
    clearAllReactions() {
      this.buttons.forEach(button => button.reaction = null);
    },
    async handleCorrectAnswer(button) {

      // has this been clicked yet? if so, don't give them points again.
      if (!this.correctPresses.includes(button.index)) {
        this.increaseScore(this.scoreUpHint);
        this.correctPresses.push(button.index);
        this.setEffect(this.assets.audio.correct);
        await this.setButtonReaction(button, 'correct', 1000);

        // we're finished with this sequence, so we're either going to go to the next sequence or quiz them
        if (this.correctPresses.length === this.totalTargetDots) {
          this.setTimer('false');
          await this.sleep(500);
          this.buttons.forEach(function (button) {
            if (!button.correct)
              button.hidden = true;
          });

          await this.sleep(1500);

          this.buttons.forEach(function (button) {
            if (button.correct) button.hidden = true;

          });

          this.addToCorrectAnswers(this.totalTargetDots);

          this.clearAllReactions();
          await this.sleep(500);

          this.sequencePosition += 1;
          // is this the last sequence? if so let's end the sequence and quiz them
          if (this.sequencePosition > this.sequenceLength) {

            this.showModal = true;
            this.sequencePosition = 1;
            return;
          }

          // if not let's go to the next sequence
          await this.setupButtons();
          await this.showGameplayHints();
        }
      }
    },
    async handleIncorrectAnswer(button) {
      this.incorrectAnswers = true;
      console.log('not the correct color');
      this.setEffect(this.assets.audio.incorrect);
      this.decreaseScore(10);

      await this.setButtonReaction(button, 'incorrect', 1000);
      await this.sleep(1400);
      await this.setButtonReaction(button, '', 1000);

    },
    /**
     * Evaluate the click correctness on a play button.
     * @param button
     * @returns {Promise<void>|boolean}
     */
    async evaluateClick(button) {
      if (this.playDisabled) {
        return false;
      }
      // if this is hint mode, they should only be able to click the hints. it should give them points
      // otherwise there should be no reaction whatsoever
      if (this.hintedButton.includes(button.index)) {
        this.clearTimerInterval();
        if (button.correct) {
          await this.handleCorrectAnswer(button);
        } else {
          await this.handleIncorrectAnswer(button);
        }
      }
    },
    /**
     * Determine the next game phase based on current trial and round.
     * @param wasTrialCorrect
     * @returns {Promise<void>}
     */
    async determineNextGamePhase(wasTrialCorrect) {
      await this.setGameLevelAndSaveTrialResults();

      if (wasTrialCorrect) {
        this.correctThisRound = this.correctThisRound + 1;
      }

      await this.sleep(100); // wait
      this.showModal = false;
      if (!this.gamePlayComplete) {
        console.log('gameplay not complete');
        this.remainingTrials ? await this.goToNextTrial() : await this.getRoundScoreAndGoToNext();
      } else {
        console.log('gameplay is complete');
        await this.handleGamePlayComplete();
      }
    },
    async handleGamePlayComplete() {
      console.log('handle game play complete');
      this.stopMusic();
      await this.endRoundScore();
      this.setEffect(this.assets.audio.gameEnd);

      this.increaseTickets(8);
      this.setTicketEffect();
      // all of the answers are correct
      // game play is complete. confetti or whatever
      this.playDisabled = true;
      await this.updatePlayLevel({
        gameId: this.gameId,
        play_id: this.play_id,
        level: Math.max(1, this.level),
        tickets: this.tickets,
        stopTime: new Date().getTime()
      });

      this.clearCorrectAnswers();

      this.movedUpALevel ? this.showMovedUpALevelModal(true) : this.showEndOfGameModal();
    },

    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    /**
     * Go to the next trial and reset appropriate values.
     */
    async goToNextTrial() {
      this.playDisabled = true;
      this.isNewTrial = true;
      this.modalMessageReset();
      if (!this.remainingTrials) {
        this.resetTrialsCorrect();
      }
      this.startNewRound();
      if (this.currentTrial === 1) {
        this.clearTrialStatus();
      }
      this.totalAnswers = 0;

      await this.sleep(1600); // so we see stuff before it goes away?
      this.clearCorrectAnswers();
      this.playDisabled = false;

      await this.getSettings(); // this will actually get the user, grid data
      await this.setupButtons();

      await this.animation.to(this.container, 1.5, {y: 0, ease: this.tweenExpo.easeOut});

      if (this.currentTrial === 1) this.correctThisRound = 0; // reset this for new rounds

      this.playStart = new Date().getTime(); // start counting the next trial time
      await this.showGameplayHints();
    },
    /**
     * Calculate scoring and leveling based on the number of correct answers in the round.
     */
    async endRoundScore() {

      if (this.allAnswersCorrect) {
        console.log('got all of the answers correct, go up a level');

        await this.goUpLevel();
        this.increaseTickets(4);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('some answers are correct, stay at same level');
      }

      if (this.noAnswersCorrect) {
        console.log('all of the answers are wrong, go down a level');
        this.goDownLevel();
      }

      this.incorrectAnswers = false;
      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });
    },
    /**
     * Go to the next round and reset appropriate values.
     */
    async goToNextRound() {
      this.endRoundText = '';

      if (this.allAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.all;
      }

      if (this.someAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.some;
      }

      if (this.noAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      this.incorrectAnswers = false;

      if (!this.gamePlayComplete) {
        this.setModalMessage({
          title: this.formatText(
              gamePlayCopy.rounds.end,
              {round: this.currentRound},
              false
          ),
          body: this.formatText(this.endRoundText, {correct: this.trialsCorrect}),
          actionButton: {
            action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
            text: gamePlayCopy.rounds.playNext
          }
        });
      }
    },
    /**
     * Get the button classes based on game state.
     * @param button
     * @returns {{correct: boolean, incorrect: boolean, 'play-disabled': boolean, 'hint-mode': boolean}}
     */
    buttonClasses(button) {
      let currentButton = this.buttons[button];
      return {
        'play-disabled': this.playDisabled && !currentButton.isActive,
        'hint-mode': this.hintedButton.includes(currentButton.index),
        'correct': currentButton.reaction === 'correct',
        'incorrect': currentButton.reaction === 'incorrect'
      };
    }
  },
  computed: {
    allAnswersCorrect() {
      return this.trialsCorrect === this.trials;
    },
    someAnswersCorrect() {
      return this.trialsCorrect > 0 && this.trialsCorrect < this.trials;
    },
    noAnswersCorrect() {
      return this.trialsCorrect === 0;
    },
    buttonStates() {
      return this.buttons.map(button => this.getButtonState(button));
    }
  },
  created() {
    // initialize any random number generators
    Object.keys(this.randomNumbers).forEach(key => {
      this.randomNumbers[key] = this.createUniqueRandomGenerator();
    });
  },
  async mounted() {
    await this.initializeGamePlay(); // start round 1, trial 1

  }
};
